import Vue from 'vue';
import VueRouter from 'vue-router';
// @ts-ignore
import store from '@/store';
import AdminService from '@/packages/Admin/src/services/AdminService';
// eslint-disable-next-line import/no-cycle
import ChannelService from '@/packages/Channel/services/ChannelService';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'admin.dashboard',
    meta: {
      keepAlive: true,
      admin: true,
      requiresAuth: true,
      components: {
        TextBlock: false,
      },
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin.dashboard" */ '@/packages/Admin/src/resources/views/Dashboard.vue'),
  },
  {
    path: '/admin/order/view/:orderID',
    name: 'admin.order.view',
    meta: {
      keepAlive: true,
      admin: true,
      requiresAuth: true,
      components: {
        TextBlock: false,
      },
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin.order.view" */ '@/packages/Admin/src/resources/views/Order/View.vue'),
  },
  {
    path: '/admin/order/view/:orderID/options',
    name: 'admin.order.view.options',
    meta: {
      keepAlive: true,
      admin: true,
      requiresAuth: true,
      components: {
        TextBlock: false,
      },
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin.order.view" */ '@/packages/Admin/src/resources/views/Order/OrderViewOptions.vue'),
  },
  {
    path: '/admin/order/view/:orderID/options/communication',
    name: 'admin.order.view.options.communication',
    meta: {
      keepAlive: true,
      admin: true,
      requiresAuth: true,
      components: {
        TextBlock: false,
      },
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin.order.view" */ '@/packages/Admin/src/resources/views/Order/OrderViewCommunication.vue'),
  },
  {
    path: '/admin/login',
    name: 'admin.login',
    meta: {
      keepAlive: true,
      requiresAuth: false,
      components: {
        TextBlock: false,
      },
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin.login" */ '@/packages/Admin/src/resources/views/Auth/Login.vue'),
  },
  {
    path: '/admin/channels',
    name: 'admin.channels',
    meta: {
      keepAlive: true,
      requiresAuth: true,
      components: {
        TextBlock: false,
      },
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin.login" */ '@/packages/Channel/resources/views/index.vue'),
  },
  {
    path: '/admin/channel/view/:channelID',
    name: 'admin.channel.view',
    meta: {
      keepAlive: true,
      admin: true,
      requiresAuth: true,
      components: {
        TextBlock: false,
      },
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin.order.view" */ '@/packages/Channel/resources/views/channel.vue'),
  },
  {
    path: '/admin/channel/:channelID/products/add',
    name: 'admin.channel.add.product',
    meta: {
      keepAlive: true,
      admin: true,
      requiresAuth: true,
      components: {
        TextBlock: false,
      },
    },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "admin.order.view" */ '@/packages/Admin/src/resources/views/Product/AddProduct.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    let position = { x: 0, y: 0 };
    // Keep scroll position when using browser buttons
    if (savedPosition) {
      position = savedPosition;
    }

    // Workaround for transitions scrolling to the top of the page
    // However, there are still some problems being fixed by the vue team
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position);
      }, 200);
    });
  },
});


// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // router.go('/admin/login')
  // return next({ name:'admin.login' })

  async function setChannelContext(channelID: string) {
    if (!store.state.app.channel) {
      await ChannelService.getChannel(channelID);
    }
  }

  if (to.params.channelID && store.state.userAuthenticated) {
    setChannelContext(to.params.channelID);
  } else {
    store.commit('mutateChannel', null);
  }

  if (to.matched.some(m => m.meta.requiresAuth) && store.state.userAuthenticated) {
    // Check if token has expired
    // @ts-ignore
    if (window.TokenService.expired) {
      // login again and obtain new token
      const { credentials } = store.state.app;
      if (credentials) {
        AdminService.login(credentials.email, credentials.password);
      } else {
        return next({ name: 'admin.login', replace: true, params: { redirect: to.fullPath } });
      }
    }
  }

  if (to.matched.some(m => m.meta.requiresAuth) && !store.state.userAuthenticated) {
    const { credentials } = store.state.app;
    if (credentials.email && credentials.password) {
      AdminService.login(credentials.email, credentials.password);
    } else {
      return next({ name: 'admin.login', replace: true });
    }
  }


  return next();
});


export default router;
