import axios from 'axios';
// @ts-ignore
import store from '@/store';
import AdminService from '@/packages/Admin/src/services/AdminService';

const retailAPIClient = axios.create();

retailAPIClient.interceptors.request.use(async (config) => {
  if (store.state.authToken) {
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = `Bearer ${store.state.authToken}`;
  }
  return config;
});

retailAPIClient.interceptors.response.use(response => response,
  async (error) => {
    if (error.response) {
      if (error.response.status === 401) {
      // Extract the below lines to a service
        const { credentials } = store.state.app;
        await AdminService.login(credentials.email, credentials.password);
      }
    }

    return Promise.reject(error);
  });

export default retailAPIClient;
