<template>
  <v-app id="app" >

    <nav-drawer></nav-drawer>


    <v-main :style="'background-color:' + this.$vuetify.theme.currentTheme.background.base + '; color:' + this.$vuetify.theme.currentTheme.onBackground + ';'">

      <v-container style="min-height: 100vh">

      <transition name="slide-x-reverse-transition" mode="out-in" @after-enter="afterEnter" appear>
        <keep-alive :key="$route.fullPath">
          <router-view v-if="$route.meta.keepAlive" :key="$route.fullPath"></router-view>
        </keep-alive>
      </transition>

      <transition name="slide-x-transition" mode="out-in" @after-enter="afterEnter" appear>
        <router-view v-if="!$route.meta.keepAlive" :key="$route.fullPath"></router-view>
      </transition>

      </v-container>

    </v-main>


  <bottom-bar></bottom-bar>
  </v-app>
</template>

<script>
import NavDrawer from '@/components/NavDrawer';
import BottomBar from '@/components/BottomBar';

// import AvatarDialog from "@/components/AvatarDialog"

export default {
  components: {
    // AvatarDialog
    NavDrawer,
    BottomBar,
  },
  props: {
    source: String,
  },
  data: () => ({
    ready: false,
  }),
  computed: {
    channel() {
      if (this.$store.state.app.channel) {
        return this.$store.state.app.channel;
      }
      return null;
    },
    applicationCurrency() {
      if (this.$store.state.app.currency) {
        return this.$store.state.app.currency.code;
      }
      return null;
    },
    applicationLocale: {
      // getter
      get() {
        if (this.$store.state.app.locale) {
          return this.$store.state.app.locale.code;
        }
        return process.env.VUE_APP_I18N_LOCALE;
      },
      // setter
      set(newValue) {
        this.$store.commit('mutateLocale', newValue);
      },
    },
    currencies() {
      if (this.$store.state.app.channel) {
        return this.$store.state.app.channel.currencies;
      }
      return null;
    },

  },
  async mounted() {

  },
  methods: {
    afterEnter() {
      this.$root.$emit('scrollAfterEnter');
    },
    async getChannel() {
      ChannelService.getChannelByCode(process.env.VUE_APP_CHANNEL)
        .then((channel) => {
          axios.interceptors.request.use((config) => {
            config.params = config.params || {};
            config.params.locale = this.$store.state.app.locale ? this.$store.state.app.locale.code : process.env.VUE_APP_I18N_LOCALE;
            config.params.currency = this.$store.state.app.currency ? this.$store.state.app.currency.code : process.env.VUE_APP_DEFAULT_CURRENCY_CODE;
            config.params.channel = this.$store.state.app.channel ? this.$store.state.app.channel.code : process.env.VUE_APP_CHANNEL;

            return config;
          });

          this.ready = true;
          this.getSubCategories(channel.root_category_id);
        }).catch((error) => {
          console.log(error);
        });
    },
    reload() {
      location.reload();
    },
  },
};
</script>

<style scss>

  html {
    font-family: 'Roboto Mono', monospace !important;
  }

  body {
    font-family: 'Roboto Mono', monospace !important;
  }

  .v-main__wrap {
      min-height:100vh;
  }

  h1,h2,h3,h4,h5 {
    font-family: 'Playfair Display', serif;
  }

  h1 {
    font-size: '20pt';
  }

  .v-toolbar__title {
    font-family: 'Playfair Display', serif;
    font-size: 20pt;
    font-weight: bold;
    color:FFF;
  }

/*
  .brand-text-red {
    color:#fc4c43;
    font-family: 'Roboto Mono', monospace !important;
  } */


  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

</style>
