import CommonChannel from '@/packages/Channel/interfaces/CommonChannel';

export default class Channel implements CommonChannel {
  readonly id: Number;

  readonly code: String;

  readonly name: String;

  readonly hostname: String;

  // eslint-disable-next-line camelcase
  readonly updated_at: Date;

  // eslint-disable-next-line camelcase
  readonly created_at: Date;

  constructor(data:any = {}) {
    this.id = data.id ? data.id : '';
    this.code = data.code ? data.code : '';
    this.name = data.name ? data.name : '';
    this.hostname = data.hostname ? data.hostname : '';
    this.updated_at = data.updated_at ? data.updated_at : '';
    this.created_at = data.created_at ? data.created_at : '';
  }
}
