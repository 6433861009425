// @ts-ignore
import store from '../store';

export default class TokenService {
  private store: any;

  constructor() {
    this.store = store;
  }

  public token(): string {
    return this.store.state.authToken;
  }

  public expired(): boolean {
    // @ts-ignore
    return Date.now() >= (JSON.parse(atob(this.token.split('.')[1]))).exp * 1000;
  }
}
