// eslint-disable-next-line import/no-cycle
import retailAPIClient from '@/packages/Admin/src/clients/retailAPIClient';
import Channel from '@/packages/Channel/Models/Channel';
// @ts-ignore
import store from '@/store';


export default {
  async getChannels() {
    return retailAPIClient.get(`${process.env.VUE_APP_ROOT_API}/admin/channels`, {
      params: {},
    }).then((response) => {
      const channels = response.data.data.map((data: any) => new Channel(data));
      store.commit('mutateChannels', channels);
      return channels;
    }).catch((error) => {
      throw (error);
    });
  },
  async getChannel(id: String) {
    return retailAPIClient.get(`${process.env.VUE_APP_ROOT_API}/admin/channels/${id}`, {
      params: {},
    }).then((response) => {
      const channel = new Channel(response.data.data);
      store.commit('mutateChannel', channel);
      return channel;
    }).catch((error) => {
      throw (error);
    });
  },
};
