import axios from 'axios';
// @ts-ignore
import store from '@/store';
import TokenService from '@/services/TokenService';

export default {
  login(email: String, password: String) {
    return axios.post(`${process.env.VUE_APP_ROOT_API}/admin/login`, {
      email,
      password,
    }).then((response) => {
      store.commit('mutateUserAuthenticated', true);
      store.commit('mutateAuthToken', response.data.token);
      axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
      // @ts-ignore
      window.TokenService = new TokenService();
      return response;
    }).catch((error) => {
      throw error;
    });
  },
};
