<template>
  <v-bottom-navigation
    background-color="primary"
    dark
    fixed
    :app="true"
  >
    <v-row align="center">
      <v-col cols="12" align="center">
        <v-btn to="/" color="primary">
          <span>Latest Orders</span>
          <v-icon>mdi-history</v-icon>
        </v-btn>

        <v-btn color="primary" to="/revenue">
          <span>Revenue</span>
          <v-icon>mdi-cash</v-icon>
        </v-btn>

        <v-btn color="primary" @click.stop="drawer = !drawer">
          <span>Menu</span>
          <v-icon>mdi-menu</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-bottom-navigation>
</template>

<script>
export default {
  name: 'TopBar',
  data: () => ({

  }),
  mounted() {

  },
  computed: {
    drawer: {
      // getter
      get() {
        return this.$store.state.app.drawer;
      },
      // setter
      set(newValue) {
        this.$store.commit('mutateDrawer', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
