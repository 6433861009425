<template>
  <transition name="fade-transition" >
    <v-navigation-drawer
      v-model="drawer"
      color="background"
      style="border-right:1px solid #f1f1f1;"
      :style="'border-right:2px solid #f1f1f1; color:' + this.$vuetify.theme.currentTheme.onBackground + '; max-height:100%; z-index:999;'"
      :clipped="$vuetify.breakpoint.lgAndUp"
      :app="true"
      :right="true"
      overlay-color="primary"
      overlay-opacity="0.0"
      elevation="4"
    >
      <v-list dense height="100vh">

        <v-divider></v-divider>

        <!-- <v-list-item
          link
          to="/avatar-designer"
        >
          <v-list-item-content>
            <v-list-item-title>
              Avatar Designer
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->

        <!-- <v-list-item
          link
          to="/print-product-designer"
        >
          <v-list-item-content>
            <v-list-item-title>
              Print Product Designer (Admin)
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item> -->


        <v-list-item
          link
          :to="'/' + locale.code + '/page/terms-and-conditions'"
        >
          <v-list-item-content>
            <v-list-item-title>
              Dashboard
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          :to="'/' + locale.code + '/page/privacy-policy'"
        >
          <v-list-item-content>
            <v-list-item-title>
              Products
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          link
          to="/admin/channels"
        >
          <v-list-item-content>
            <v-list-item-title>
              Channels
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>
  </transition>
</template>

<script>
export default {
  name: 'NavDrawer',
  data: () => ({
    pages: [
      {
        name: 'Collections',
        path: '/collections/',
      },
      {
        name: 'Inspiration',
        path: '/page/interior-design-inspiration',
      },
      {
        name: 'Our Story',
        path: '/page/our-story',
      },
    ],
    items: [],
  }),
  mounted() {

  },
  computed: {
    cartItemCount() {
      if (this.$store.state.checkout.cart != null) {
        return parseInt(this.$store.state.checkout.cart.items_count);
      }
      return 0;
    },
    ApplicationTitle() {
      return this.$store.state.app.title;
    },
    locale() {
      if (!this.$store.state.app.locale) {
        // Set default locale
        return { code: process.env.VUE_APP_I18N_LOCALE };
      }
      return this.$store.state.app.locale;
    },
    drawer: {
      // getter
      get() {
        return this.$store.state.app.drawer;
      },
      // setter
      set(newValue) {
        this.$store.commit('mutateDrawer', newValue);
      },
    },
  },
  methods: {

  },
};
</script>

<style lang="scss" scoped>

.v-navigation-drawer__content {
  border-top: 1px solid black;
}

.product {
  cursor: pointer;
}

#product-image-container {
  min-height:230px;
}

.product-image:hover {
    // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
    -webkit-transform: scale(1.02, 1.02);
    transform: scale(1.02, 1.02);
    -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.product-title {
  font-family: 'Playfair Display', serif;
}

.product-subtitle {
  font-family: 'Roboto Mono', monospace;
}

.product:active {
  // box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  -webkit-transform: scale(0.98, 0.98);
  transform: scale(0.98, 0.98);
  -webkit-transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
}


</style>
