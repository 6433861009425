import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import VendorOrder from '@/packages/Admin/src/Models/VendorOrder';
import Channel from '@/packages/Channel/Models/Channel';
import Product from '@/packages/Product/src/Models/Product';
import ChannelLocale from '@/constructors/ChannelLocale';
import Currency from '@/constructors/Currency';
import Credentials from '@/constructors/Credentials';

Vue.use(Vuex);


const store = new Vuex.Store({
  state: {
    app: {
      drawer: false,
      title: '',
      locale: ChannelLocale,
      channel: Channel,
      channels: [],
      currency: Currency,
      menuItems: {},
      credentials: {
        email: '',
        password: ''
      },
    },
    admin: {
      orders: [],
      views: {
        OrderView: {
          tab: 0,
        },
      },
    },
    userAuthenticated: false,
    authToken: '',
    products: [],
  },
  mutations: {
    mutateAppTitle(state, title) {
      state.app.title = title;
    },
    mutateDrawer(state, value) {
      state.app.drawer = value;
    },
    mutateCredentials(state, credentials) {
      state.app.credentials = new Credentials(credentials.email, credentials.password);
    },
    mutateUserAuthenticated(state, userAuthenticated) {
      state.userAuthenticated = userAuthenticated;
    },
    mutateAuthToken(state, token) {
      state.authToken = token;
    },
    mutateLocale(state, locale) {
      state.app.locale = locale;
    },
    mutateCurrency(state, currency) {
      state.app.currency = currency;
    },
    mutateMenuItems(state, menuItems) {
      state.app.menuItems = menuItems;
    },
    mutateAdminOrders(state, orders) {
      state.admin.orders = orders;
    },
    mutateVendorOrder(state: any, vendorOrder: VendorOrder) {
      // eslint-disable-next-line array-callback-return
      const foundOrder = state.admin.orders.find((order:any) => {
        // eslint-disable-next-line no-unused-expressions
        return order.id == vendorOrder.external_id;
      });
      if (foundOrder) {
        foundOrder['vendorOrder'] = vendorOrder;
      }
    },
    mutateOrderViewTab(state, tab) {
      state.admin.views.OrderView.tab = tab;
    },
    mutateChannel(state, channel) {
      state.app.channel = channel;
    },
    mutateChannels(state, channels: []) {
      state.app.channels = channels;
    },
  },
  getters: {
    // eslint-disable-next-line max-len,camelcase
    getProductByURLKey: state => (url_key: string) => state.products.find((product: Product) => product.url_key === url_key),
    getChannelById: state => (id: number) => state.app.channels.find((channel: Channel) => channel.id === id),
  },
  actions: {
  },
  modules: {
  },
  plugins: [createPersistedState()],
});

export default store;
