import Vue from 'vue';

import axios from 'axios';
// @ts-ignore
import VueWorker from 'vue-worker';
import App from './App.vue';
import router from './router';
// @ts-ignore
import store from './store';
import TokenService from './services/TokenService';
// import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import './registerServiceWorker';

Vue.use(VueWorker);

if (store.state.authToken) {
  // @ts-ignore
  window.TokenService = new TokenService();
  axios.defaults.headers.common.Authorization = `Bearer ${store.state.authToken}`;
}

axios.defaults.withCredentials = true;

// @ts-ignore
window.axios = axios;

Vue.config.productionTip = false;
Vue.prototype.$http = axios;

// create Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
});


// @ts-ignore
window.axiosInstance = axiosInstance;

// Configure app
store.commit('mutateAppTitle', process.env.VUE_APP_TITLE);

// @ts-ignore
window.eventBus = new Vue();


const app = new Vue({
  router,
  // @ts-ignore
  store,
  i18n,
  // @ts-ignore
  vuetify,
  render: h => h(App),
  mounted: () => setTimeout(() => { document.dispatchEvent(new Event('x-app-rendered')); }, 15000), // wait 15 sec. and take snapshot
}).$mount('#app');
