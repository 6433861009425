import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
// @ts-ignore
import Vuetify from 'vuetify/lib';
// eslint-disable-next-line import/extensions
import light from '@/packages/petitgalerie-ui/src/theme/theme.ts';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    dark: false,
    themes: {
      light,
    },
  },
});
